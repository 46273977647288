import React from 'react';
import Agency from '../Components/Agency/Agency'


class agencyScript extends React.Component{

    constructor(props){
        super(props);
        this.state={
            
        }
    }

    componentDidMount(){

    }

    render(){
        return(
            <Agency></Agency>   
        )
    }
}

export default agencyScript;