import React from 'react'

import './style.css'

export default function InfoDes() {
    return (
        <div className="infoDes-container">
            <h1>Las Vegas</h1>

            <div className="promo-container" >

                <div className="promo">
                    <p>Flyer 1</p>
                </div>

                <div className="promo">
                    <p>Flyer 1</p>
                </div>

                <div className="promo">
                    <p>Flyer 1</p>
                </div>

                <div className="promo">
                    <p>Flyer 1</p>
                </div>
                <div className="promo">
                    <p>Flyer 1</p>
                </div>
                <div className="promo">
                    <p>Flyer 1</p>
                </div>

            </div>


            
        </div>
    )
}
